import React from "react";
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <>
      <div className="footer">
        <p>© 2022 Hohm, LLC. All rights reserved. </p>
        <p>Privacy · <Link to="TermsOfUse.pdf">Terms</Link> · Sitemap · <Link to="/locations">Locations</Link> · <Link to="tel:913-220-5546">Contact Us</Link></p>
      </div>


    </>

  );
}

export default Footer;
