import React from "react";
import { CRow, CCol, CImage, CButton, CCard, CForm, CFormInput, CFormTextarea, CContainer, CCardBody } from "@coreui/react";
import { Maincontents } from "../../Maincontents";
import hohmScreen from "../../assets/screenshots/HohmScreen.png"
import { Link } from "react-router-dom";
import Footer from "components/common/footer/Footer";
import Contents from "./contents/Contents";
import HowItWorksVendor from "./how-it-works/HowItWorksVendor";
import NavBar from "components/common/navbar/Navbar";
import { UserType } from "enums/UserType";

interface VendorLandingPageProps {
  state?: string,
  area?: string,
}

function VendorLandingPage({ state, area }: VendorLandingPageProps) {
  return (
    <>
      <NavBar userType={UserType.servicer} />
      <CContainer fluid className="hero-image servicer-horizontal mx-0 px-0">
        <CRow className="h-75 ps-lg-5 ps-md-0 ps-sm-0 ps-0">
          <CCol className="px-5 pb-5 mb-5 text-white d-flex align-items-end" lg={5} md={6} sm={12}>
            <CContainer>
              <h1 className="mb-0 fw-bold">Welcome Hohm{area && `, ${area}`}!</h1>
              <p>
                For {area ?? "Kansas City"} homeowners looking for the maintenance-free lifestyle of renting.
              </p>
              <CButton
                color="light"
                href="https://appurl.io/CWRd-S-HUC"
              >
                <i className="fa fa-mobile-screen me-2" />
                <b>Get the App</b>
              </CButton>
            </CContainer>
          </CCol>
          <CCol className="my-auto px-5" lg={7} md={6} sm={12}>
            <CRow>
              <CCol lg={6} sm={6} className="mx-auto">
                <CCard className="mx-auto">
                  <CCardBody>
                    <h2 className="text-center">Join the Hohm Network!</h2>
                    <p className="text-center">Become a trusted partner for homeowners around Kansas City.</p>
                    <CForm action="https://submit-form.com/GQEKETV2">
                      <CFormInput
                        type="text"
                        id="businessName"
                        name="businessName"
                        placeholder="Business Name"
                        className="my-3"
                        required
                      />
                      <CFormInput
                        type="email"
                        id="email"
                        name="email"
                        placeholder="business@example.com"
                        className="my-3"
                        required
                      />
                      <CFormInput
                        type="tel"
                        id="phone"
                        name="phone"
                        placeholder="(123) 456-7890"
                        className="my-3"
                        required
                      />
                      <CFormTextarea
                        id="services"
                        name="services"
                        placeholder="What services does you company provide?"
                        className="my-3"
                        required
                      />
                      <CButton type="submit" className="my-3 float-end">Send</CButton>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CContainer >
      {
        area &&
        <CRow className="p-5 bg-dark">
          <CCol className="text-center text-white">
            <h2 className="mb-5">What is Hohm?</h2>
            <p className="mb-5"><b>Hohm is changing what it means to be a homeowner in {area}.</b></p>
            <p>
              Are you a homeowner looking for the maintaince-free, worry-free lifestyle of renting or maintaince-free communities?
              Hohm is an innovative solution that brings the simplicity of a maintaince-free community to the palm of your hands for
              a flat monthly fee. The fee is comparable to the cost of the monthly Home Owner's Association (HOA) fee in one of these
              communities. Hohm has partnered with some of the highest-rated, trusted names in {area} to provide high quality home
              services. Your Hohm subscription plan will be customize to the specific needs of your home (leaf removal, pool
              cleaning, etc.). Download our app today to get connected with one of our Hohm Advisors!
            </p>
          </CCol>
        </CRow>
      }
      <HowItWorksVendor />
      <CRow className="p-5 bg-dark">
        <CCol className="text-center text-white">
          <h2 className="mb-0">Home Maintenance, Simplified</h2>
          <p>
            House tasks becoming too consuming? Considering a move to a maintenance-free community? Put your house on auto-pilot with a Hohm subscription.
          </p>
        </CCol>
      </CRow>
      <CRow className="pb-5 bg-dark">
        <CCol lg={3} md={4} sm={5} xs={6} className="mx-auto">
          <CImage fluid src={hohmScreen} />
        </CCol>
      </CRow>
      <CRow className="py-5">
        <CCol className="text-center">
          <h2>Example Subscription Services</h2>
          <p className="mb-5">
            <i>Actual subscriptions customizable to your home.</i>
          </p>
          <CRow className="d-flex justify-content-center">
            <CCol xl={2}>
              <h1>
                <i className="fa fa-leaf rounded-circle border p-3" />
              </h1>
              <p>Weekly Mowing</p>
            </CCol>
            <CCol xl={2}>
              <h1>
                <i className="fa fa-water rounded-circle border p-3" />
              </h1>
              <p>Biweekly Pool Cleaning</p>
            </CCol>
          </CRow>
          <CRow className="d-flex justify-content-center">
            <CCol xl={2}>
              <h1>
                <i className="fa fa-spray-can-sparkles rounded-circle border p-3" />
              </h1>
              <p>Weekly House Cleaning</p>
            </CCol>
            <CCol xl={2}>
              <h1>
                <i className="fa fa-shirt rounded-circle border p-3" />
              </h1>
              <p>Weekly Laundry Delivery</p>
            </CCol>
            <CCol xl={2}>
              <h1>
                <i className="fa fa-arrow-down-up-across-line rounded-circle border p-3" />
              </h1>
              <p>Quarterly Air Filter Replacement</p>
            </CCol>
          </CRow>
          <CRow className="d-flex justify-content-center">
            <CCol xl={2}>
              <h1>
                <i className="fa fa-search rounded-circle border p-3" />
              </h1>
              <p>Annual Whole Home Inspection</p>
            </CCol>
            <CCol xl={2}>
              <h1>
                <i className="fa fa-screwdriver-wrench rounded-circle border p-3" />
              </h1>
              <p>Monthly Handyman Tasks Included</p>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <CRow className="p-5 bg-dark">
        <h2 className="text-white text-center">Maintain your Home with a Tap</h2>
        {Maincontents.map((item) => {
          return (
            <CCol key={item.id} md={4} sm={12} className="py-5 d-flex align-items-stretch">
              <Contents
                id={item.id}
                img={item.img}
                title={item.title}
                description={item.description}
              />
            </CCol>
          );
        })}
      </CRow>
      <Footer />
    </>
  );
}

export default VendorLandingPage;

