import { CBadge, CCol, CRow } from "@coreui/react";
import React from "react";

function HowItWorksVendor() {
  return (
    <>
      <CRow className="p-5 text-center">
        <CCol>
          <h1>How It Works</h1>
        </CCol>
      </CRow>
      <CRow className="px-5 py-3 text-center">
        <CCol className="px-lg-4 px-md-5 px-sm-5 px-5 pb-lg-0 pb-md-5 pb-sm-5 pb-5" lg={3} md={12}>
          <h1>
            <i className="fa fa-house" />
          </h1>
          <h4 className="my-lg-5">Access to Homeowners</h4>
          <p>Hohm brings "maintenance-free living" to homeowners in their current home. When they need something, we send it out to our network of service pros.</p>
          <CBadge color="dark" shape="rounded-pill">1</CBadge>
        </CCol>
        <CCol className="px-lg-4 px-md-5 px-sm-5 px-5 py-lg-0 py-md-5 py-sm-5 py-5" lg={3} md={12}>
          <h1>
            <i className="fa fa-clipboard-list" />
          </h1>
          <h4 className="my-lg-5">Get Jobs to Quote</h4>
          <p>When owners in your area request a service you provide, we'll send it to you to submit a quote.</p>
          <CBadge color="dark" shape="rounded-pill">2</CBadge>
        </CCol>
        <CCol className="px-lg-4 px-md-5 px-sm-5 px-5 py-lg-0 py-md-5 py-sm-5 py-5" lg={3} md={12}>
          <h1>
            <i className="fa fa-screwdriver-wrench" />
          </h1>
          <h4 className="my-lg-5">Complete Service</h4>
          <p>This is where you shine! Provide the high-quality service you always do!</p>
          <CBadge color="dark" shape="rounded-pill">3</CBadge>
        </CCol>
        <CCol className="px-lg-4 px-md-5 px-sm-5 px-5 py-lg-0 py-md-5 py-sm-5 py-5" lg={3} md={12}>
          <h1>
            <i className="fa fa-dollar" />
          </h1>
          <h4 className="my-lg-5">Get Paid</h4>
          <p>We collect the money automatically from the customer, then send your payout promptly!</p>
          <CBadge color="dark" shape="rounded-pill">4</CBadge>
        </CCol>
      </CRow>
    </>
  );
}

export default HowItWorksVendor;

