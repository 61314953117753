import React from "react";
import Footer from "components/common/footer/Footer";
import NavBar from "../common/navbar/Navbar";
import { CRow, CCol, CCard, CCardBody, CButton, CTableDataCell, CTable, CTableBody, CTableHead, CTableHeaderCell, CTableRow } from "@coreui/react";
import Features, { PlanType } from "components/plans/features/Features";

function BuyNow() {
  return (
    <>
      <NavBar />
      <CRow className="my-5">
        <CCol>
          <h1
            style={{ fontWeight: 850 }}
            className="text-center"
          >
            Worry Less. Live More.
          </h1>
        </CCol>
      </CRow>
      <CRow className="m-5">
        <CCol>
          <p className="text-center">Hohm's subscription plans are changing the home warranty industry. Gone are the days of useless home warranties. Say hello to Hohm - it's nice to be a homeowner again!</p>
        </CCol>
      </CRow>
      <CRow className="mx-md-5 mx-sm-0 mx-0 px-md-5 px-sm-0 px-0">
        <CCol>
          <CTable>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col"></CTableHeaderCell>
                <CTableHeaderCell scope="col">Monthly (recurring)</CTableHeaderCell>
                <CTableHeaderCell scope="col">6-month Plan (one-time)</CTableHeaderCell>
                <CTableHeaderCell scope="col">12-month Plan (one-time)</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              <CTableRow>
                <CTableHeaderCell scope="row">Basic</CTableHeaderCell>
                <CTableDataCell className="text-center">
                  <CButton
                    variant="ghost"
                    className="p-1"
                    href="https://buy.stripe.com/dR6aEK75scbQby8fZ6"
                  >
                    $70
                  </CButton>
                </CTableDataCell>
                <CTableDataCell className="text-center">
                  <CButton
                    variant="ghost"
                    className="p-1"
                    href="https://buy.stripe.com/7sIfZ4gG2dfU8lWeUZ"
                  >
                    $420
                  </CButton>
                </CTableDataCell>
                <CTableDataCell className="text-center">
                  <CButton
                    variant="ghost"
                    className="p-1"
                    href="https://buy.stripe.com/28o9AGahE1xcfOo6oq"
                  >
                    $550
                  </CButton>
                </CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">Basic<b>+</b></CTableHeaderCell>
                <CTableDataCell className="text-center">
                  <CButton
                    variant="ghost"
                    className="p-1"
                    href="https://buy.stripe.com/4gw006gG27VA1Xy3ci"
                  >
                    $100
                  </CButton>
                </CTableDataCell>
                <CTableDataCell className="text-center">
                  <CButton
                    variant="ghost"
                    className="p-1"
                    href="https://buy.stripe.com/cN29AG2Pcfo259KcMQ"
                  >
                    $600
                  </CButton>
                </CTableDataCell>
                <CTableDataCell className="text-center">
                  <CButton
                    variant="ghost"
                    className="p-1"
                    href="https://buy.stripe.com/dR6006ahEfo20Tu5kl"
                  >
                    $675
                  </CButton>
                </CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">Auto Pilot</CTableHeaderCell>
                <CTableDataCell className="text-center">
                  <CButton
                    variant="ghost"
                    className="p-1"
                    href="https://buy.stripe.com/8wM9AGblI4Joby88wD"
                  >
                    $120
                  </CButton>
                </CTableDataCell>
                <CTableDataCell className="text-center">
                  <CButton
                    variant="ghost"
                    className="p-1"
                    href="https://buy.stripe.com/5kA7sy89w4Joau44gj"
                  >
                    $720
                  </CButton>
                </CTableDataCell>
                <CTableDataCell className="text-center">
                  <CButton
                    variant="ghost"
                    className="p-1"
                    href="https://buy.stripe.com/6oEeV04Xkfo231C9AA"
                  >
                    $800
                  </CButton>
                </CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <p className="mx-5 px-5 float-end">*<i>Click price to sign up!</i></p>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <p className="mx-5 px-5 float-end">*<i>All plans only currently available in the Kansas City metro area.</i></p>
        </CCol>
      </CRow>
      <CRow className="mt-5 pt-5">
        <CCol>
          <h1
            style={{ fontWeight: 850 }}
            className="text-center"
          >
            Get to Know Your Plan.
          </h1>
        </CCol>
      </CRow>
      <CRow className="mx-5 p-5 text-center">
        <CCol lg={4} sm={12}>
          <CCard className="mb-5 border-orange">
            <CCardBody>
              <CRow>
                <CCol>
                  <h1 style={{ fontWeight: 700 }}>Basic</h1>
                  <p>Just the bare bones.</p>
                </CCol>
              </CRow>
              <CRow className="my-4">
                <CCol>
                  <h1
                    className="mb-0"
                    style={{ fontSize: 72, lineHeight: 0.8 }}
                  >
                    $70
                  </h1>
                  <p>per month</p>
                </CCol>
              </CRow>
              <CRow className="mb-4">
                <CCol>
                  <CButton
                    shape="rounded-pill"
                  >
                    <b>Sign Up</b>
                  </CButton>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <Features plan={PlanType.basic} />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol lg={4} sm={12}>
          <CCard className="mb-5 border-orange">
            <CCardBody>
              <CRow>
                <CCol className="mx-3">
                  <h1 style={{ fontWeight: 700 }}>Basic<b>+</b></h1>
                  <p>A little more coverage to help you sleep at night.</p>
                </CCol>
              </CRow>
              <CRow className="my-4">
                <CCol>
                  <h1
                    className="mb-0"
                    style={{ fontSize: 72, lineHeight: 0.8 }}
                  >
                    $100
                  </h1>
                  <p>per month</p>
                </CCol>
              </CRow>
              <CRow className="mb-4">
                <CCol>
                  <CButton
                    shape="rounded-pill"
                  >
                    <b>Sign Up</b>
                  </CButton>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <Features plan={PlanType.basicPlus} />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol lg={4} sm={12}>
          <CCard className="mb-5 border-orange">
            <CCardBody>
              <CRow>
                <CCol>
                  <h1 style={{ fontWeight: 700 }}>Auto Pilot</h1>
                  <p>Top of the line to keep you covered!</p>
                </CCol>
              </CRow>
              <CRow className="my-4">
                <CCol>
                  <h1
                    className="mb-0"
                    style={{ fontSize: 72, lineHeight: 0.8 }}
                  >
                    $120
                  </h1>
                  <p>per month</p>
                </CCol>
              </CRow>
              <CRow className="mb-4">
                <CCol>
                  <CButton
                    shape="rounded-pill"
                  >
                    <b>Sign Up</b>
                  </CButton>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <Features plan={PlanType.autoPilot} />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <Footer />
    </>
  );
}

export default BuyNow;

