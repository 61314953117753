import React from "react";
import { CRow, CCol, CButton, CButtonGroup } from "@coreui/react";
import "./TopBanner.css"
import { UserType } from "enums/UserType";

interface TopBannerProps {
  userType?: UserType,
}

function TopBanner({ userType }: TopBannerProps) {
  return (
    <CRow className="bg-navy px-md-5 px-sm-2 px-2">
      <CCol lg="6" sm="6" className="my-auto d-lg-block d-md-none d-sm-none d-none">
        <div className="d-flex flex-row my-auto">
          <div>
            <CButton
              color="light"
              variant="outline"
              shape="rounded-pill"
              className="py-0 px-2"
              href={userType === UserType.servicer ?
                "https://apps.apple.com/gb/app/hohm-vendor/id1616536276" :
                "https://apps.apple.com/gb/app/hohm-owner/id1616527938"
              }>
              <small>
                <b>Get The App</b>
              </small>
            </CButton>
          </div>
          <div className="ms-3">
            <p className="text-white mb-0"><b>Own Your Home, Not The Hassle</b></p>
            {/* The Better Home Warranty */}
          </div>
        </div>

      </CCol>
      <CCol lg="6" sm="6">
        <CButtonGroup role="group" className="float-end">
          <CButton
            color={`${userType === UserType.owner ? "light" : "dark"}`}
            href="/"
            className="px-4 rounded-0 border-0"
          >
            <small>
              <b>HOMEOWNER</b>
            </small>
          </CButton>
          <CButton
            color={`${userType === UserType.realtor ? "light" : "dark"}`}
            href="/realtor"
            className="px-4 rounded-0 border-0"
          >
            <small>
              <b>REALTOR</b>
            </small>
          </CButton>
          <CButton
            color={`${userType === UserType.servicer ? "light" : "dark"}`}
            href="/servicer"
            className="px-4 rounded-0 border-0"
          >
            <small>
              <b>SERVICER</b>
            </small>
          </CButton>
        </CButtonGroup>
      </CCol>
    </CRow>
  );
}

export default TopBanner;

