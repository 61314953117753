import { CBadge, CCol, CRow } from "@coreui/react";
import React from "react";

function HowItWorksCustomer() {
  return (
    <>
      <CRow className="p-5 text-center">
        <CCol>
          <h1>How It Works</h1>
        </CCol>
      </CRow>
      <CRow className="px-5 py-3 text-center">
        <CCol className="px-lg-4 px-md-5 px-sm-5 px-5 pb-lg-0 pb-md-5 pb-sm-5 pb-5" lg={3} md={12}>
          <h1>
            <i className="fa fa-search" />
          </h1>
          <h4 className="my-lg-5">We'll Get To Know You</h4>
          <p>Each home is unique. Your Hohm Advisor will work with you to find out what services your home needs.</p>
          <CBadge color="dark" shape="rounded-pill">1</CBadge>
        </CCol>
        <CCol className="px-lg-4 px-md-5 px-sm-5 px-5 py-lg-0 py-md-5 py-sm-5 py-5" lg={3} md={12}>
          <h1>
            <i className="fa fa-mouse-pointer" />
          </h1>
          <h4 className="my-lg-5">Select A Plan</h4>
          <p>We'll provide 3 tiers of subscription options. From just the basics, to <i><b>Auto Pilot Mode</b></i></p>
          <CBadge color="dark" shape="rounded-pill">2</CBadge>
        </CCol>
        <CCol className="px-lg-4 px-md-5 px-sm-5 px-5 py-lg-0 py-md-5 py-sm-5 py-5" lg={3} md={12}>
          <h1>
            <i className="fa fa-umbrella-beach" />
          </h1>
          <h4 className="my-lg-5">Relax</h4>
          <p>We'll take it from here! Any scheduled services will show up on the app, so you know when services providers are coming.</p>
          <CBadge color="dark" shape="rounded-pill">3</CBadge>
        </CCol>
        <CCol className="px-lg-4 px-md-5 px-sm-5 px-5 py-lg-0 py-md-5 py-sm-5 py-5" lg={3} md={12}>
          <h1>
            <i className="fa fa-wrench" />
          </h1>
          <h4 className="my-lg-5">Don't Worry</h4>
          <p>Need something that's not included in your plan? Submit a request in seconds and we'll collect quotes from our trusted partners.</p>
          <CBadge color="dark" shape="rounded-pill">4</CBadge>
        </CCol>
      </CRow>
    </>
  );
}

export default HowItWorksCustomer;

