import React from "react";
import Footer from "components/common/footer/Footer";
import NavBar from "../common/navbar/Navbar";
import { CRow, CCol, CCard, CCardBody, CButton } from "@coreui/react";
import "./Plans.css"
import Features, { PlanType } from "./features/Features";

function Plans() {
  return (
    <>
      <NavBar />
      <CRow className="my-5">
        <CCol>
          <h1
            style={{ fontWeight: 850 }}
            className="text-center"
          >
            Worry Less. Live More.
          </h1>
        </CCol>
      </CRow>
      <CRow className="m-5">
        <CCol>
          <p className="text-center">Hohm's subscription plans are changing the home warranty industry. Gone are the days of useless home warranties. Say hello to Hohm - it's nice to be a homeowner again!</p>
        </CCol>
      </CRow>
      <CRow className="m-5 text-center">
        <CCol lg={4} sm={12}>
          <CCard className="mb-5 border-orange">
            <CCardBody>
              <CRow>
                <CCol>
                  <h1 style={{ fontWeight: 700 }}>Basic</h1>
                  <p>Just the bare bones.</p>
                </CCol>
              </CRow>
              <CRow className="my-4">
                <CCol>
                  <h1
                    className="mb-0"
                    style={{ fontSize: 72, lineHeight: 0.8 }}
                  >
                    $70
                  </h1>
                  <p>per month</p>
                </CCol>
              </CRow>
              <CRow className="mb-4">
                <CCol>
                  <CButton
                    shape="rounded-pill"
                    href="https://buy.stripe.com/dR6aEK75scbQby8fZ6"
                  >
                    <b>Sign Up</b>
                  </CButton>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <Features plan={PlanType.basic} />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol lg={4} sm={12}>
          <CCard className="mb-5 border-orange">
            <CCardBody>
              <CRow>
                <CCol className="mx-3">
                  <h1 style={{ fontWeight: 700 }}>Basic<b>+</b></h1>
                  <p>A little more coverage to help you sleep at night.</p>
                </CCol>
              </CRow>
              <CRow className="my-4">
                <CCol>
                  <h1
                    className="mb-0"
                    style={{ fontSize: 72, lineHeight: 0.8 }}
                  >
                    $100
                  </h1>
                  <p>per month</p>
                </CCol>
              </CRow>
              <CRow className="mb-4">
                <CCol>
                  <CButton
                    shape="rounded-pill"
                    href="https://buy.stripe.com/4gw006gG27VA1Xy3ci"
                  >
                    <b>Sign Up</b>
                  </CButton>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <Features plan={PlanType.basicPlus} />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol lg={4} sm={12}>
          <CCard className="mb-5 border-orange">
            <CCardBody>
              <CRow>
                <CCol>
                  <h1 style={{ fontWeight: 700 }}>Auto Pilot</h1>
                  <p>Top of the line to keep you covered!</p>
                </CCol>
              </CRow>
              <CRow className="my-4">
                <CCol>
                  <h1
                    className="mb-0"
                    style={{ fontSize: 72, lineHeight: 0.8 }}
                  >
                    $120
                  </h1>
                  <p>per month</p>
                </CCol>
              </CRow>
              <CRow className="mb-4">
                <CCol>
                  <CButton
                    shape="rounded-pill"
                    href="https://buy.stripe.com/8wM9AGblI4Joby88wD"
                  >
                    <b>Sign Up</b>
                  </CButton>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <Features plan={PlanType.autoPilot} />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow className="m-5">
        <CCol>
          <p className="text-end">*<i>All plans only currently available in the Kansas City metro area.</i></p>
        </CCol>
      </CRow>
      <CRow className="m-5">
        <CCol>
          <p className="text-center">Are you a real estate professional or title company looking for 6-month and 12-month plans? <a href="/buy">Click here</a></p>
        </CCol>
      </CRow>
      <Footer />
    </>
  );
}

export default Plans;

