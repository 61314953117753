import React from "react";
import { CRow, CCol } from "@coreui/react";
import { stateCities } from "../../assets/datasets/kc-areas";
import Banner from "../landing-page/banner/Banner";
import { Link } from "react-router-dom";

function LocationsList() {
  return (
    <>
      <Banner />
      <CRow className="p-5">
        <CCol>
          <h2 className="text-white text-center">Are We In Your Neighborhood?</h2>
          {stateCities.map((state) => {
            return (
              <CRow>
                <CCol>
                  <h2>{state.state_long}</h2>
                  <p>{
                    state.areas.map((area, index) =>
                      <>
                        {index !== 0 && " · "}
                        <Link to={`/${state.state_short}/${area.replace(" ", "-")}`}>
                          {area}
                        </Link>
                      </>
                    )
                  }</p>
                </CCol>
              </CRow>
            );
          })}
        </CCol>
      </CRow >
    </>
  );
}

export default LocationsList;

