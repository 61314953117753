import React from "react";
import {
  CRow,
  CCol,
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CTooltip
} from "@coreui/react";

export enum PlanType { basic, basicPlus, autoPilot }

interface FeaturesProps {
  plan: PlanType,
}

function Features({ plan }: FeaturesProps) {
  return (
    <CAccordion flush alwaysOpen>
      <CAccordionItem itemKey={0}>
        <CAccordionHeader>
          <i className="fa fa-user me-3" /> Hohm Concierge
        </CAccordionHeader>
        <CAccordionBody>
          <CRow>
            <CCol className="text-start">
              <i className="fa fa-check-circle" /> Access to Hohm mobile app
              <br />
              <i className="fa fa-check-circle" /> Personalized Hohm Consultant
              <br />
              <i className="fa fa-check-circle" /> Personalized Customer Service Line
              <br />
              <i className="fa fa-check-circle" /> Request additional home services with a tap &nbsp;
              <CTooltip
                content="From mowing, to laundry delivery, to roofing, requesting home services with Hohm is easier than ever before."
              >
                <i className="fa fa-info-circle" />
              </CTooltip>
            </CCol>
          </CRow>
        </CAccordionBody>
      </CAccordionItem>
      <CAccordionItem itemKey={1}>
        <CAccordionHeader>
          <i className="fa fa-fan me-3" /> Heating & Cooling
        </CAccordionHeader>
        <CAccordionBody>
          <CRow>
            <CCol className="text-start">
              <i className="fa fa-check-circle" /> Heating Repairs &nbsp;
              <CTooltip
                content="Up to 2 furnaces are included with your warranty. Because boilers cost more to service, they require additional coverage ($110)."
              >
                <i className="fa fa-info-circle" />
              </CTooltip>
              <br />
              <i className="fa fa-check-circle" /> Cooling Repairs &nbsp;
              <CTooltip
                content="Air conditioners, heat pumps, air handlers, mini-splits, geothermals, and PTAC units are included. If you have more than 2 systems, additional coverage is required ($120)."
              >
                <i className="fa fa-info-circle" />
              </CTooltip>
              <br />
              <i className="fa fa-check-circle" /> Thermostats
              <br />
              <i className="fa fa-check-circle" /> Humidifiers &nbsp;
              <CTooltip
                content="Whole house humidifiers only."
              >
                <i className="fa fa-info-circle" />
              </CTooltip>
              <br />
              <i className="fa fa-check-circle" /> Refrigerant &nbsp;
              <CTooltip
                content="Due to the EPA phaseout of R-22 refrigerant, R-22 is not a covered option under the warranty."
              >
                <i className="fa fa-info-circle" />
              </CTooltip>
            </CCol>
          </CRow>
        </CAccordionBody>
      </CAccordionItem>
      <CAccordionItem itemKey={2}>
        <CAccordionHeader>
          <i className="fa fa-bath me-3" /> Plumbing
        </CAccordionHeader>
        <CAccordionBody>
          <CCol className="text-start">
            <i className="fa fa-check-circle" /> Drains &nbsp;
            <CTooltip
              content="Drain stoppages, leaks, or breaks within the foundation walls. Sewer line stoppages will be augered up to 100 feet from the clean out."
            >
              <i className="fa fa-info-circle" />
            </CTooltip>
            <br />
            <i className="fa fa-check-circle" /> Water heater &nbsp;
            <CTooltip
              content="First system only. Additional water heaters require add-on coverage ($90). Optional coverage is available for tankless or power vent water heaters."
            >
              <i className="fa fa-info-circle" />
            </CTooltip>
            <br />
            <i className="fa fa-check-circle" /> Laundry Box
            <br />
            <i className="fa fa-check-circle" /> Pressure Regulator (if present)
            <br />
            <i className="fa fa-check-circle" /> Faucets &nbsp;
            <CTooltip
              content="All faucets covered including instant hot faucets, water purifier faucets, over-the-stove pot fillers (repair only), and hose bibbs. If we can't repair your faucet, you have 3 options included with your service fee: 1. replace it with a standard, builder-grade faucet 2. replace it with an upgraded option (extra expense) 3. install your pre-purchased faucet"
            >
              <i className="fa fa-info-circle" />
            </CTooltip>
            <br />
            <i className="fa fa-check-circle" /> Tub & Shower &nbsp;
            <CTooltip
              content="Includes valeves, tub spouts, showerheads, drains, and diverters. Hand-held showerheads are not included."
            >
              <i className="fa fa-info-circle" />
            </CTooltip>
            <br />
            <i className="fa fa-check-circle" /> Built-in Whirlpool &nbsp;
            <CTooltip
              content="Motors and pump assemblies only."
            >
              <i className="fa fa-info-circle" />
            </CTooltip>
            <br />
            <i className="fa fa-check-circle" /> Toilets &nbsp;
            <CTooltip
              content="Includes stoppages (no 30-day warranty), mechanical components, wax rings, and toilet seats. If a toilet is non-repairable due to age or unavailable parts, we will replace it with a standard model."
            >
              <i className="fa fa-info-circle" />
            </CTooltip>
            <br />
            <i className="fa fa-check-circle" /> Sump pumps &nbsp;
            <CTooltip
              content="All properly installed sump pumps and piping are included. We respond to emergencies 24/7."
            >
              <i className="fa fa-info-circle" />
            </CTooltip>
            <br />
            <i className="fa fa-check-circle" /> Water service lines &nbsp;
            <CTooltip
              content="To the property line. Available only during the initial home warranty term."
            >
              <i className="fa fa-info-circle" />
            </CTooltip>
            <br />
            <i className="fa fa-check-circle" /> Pipes &nbsp;
            <CTooltip
              content="Covers properly installed interior water and drain pipes. Does not include damage from freezing pipes, improperly installed pipes, curb stops, removing and replacing water service meter."
            >
              <i className="fa fa-info-circle" />
            </CTooltip>
            <br />
            <i className="fa fa-check-circle" /> Garbage disposal &nbsp;
            <CTooltip
              content="Covers stoppages. If we are unable to repair it, we will replace it with a standard-grade model."
            >
              <i className="fa fa-info-circle" />
            </CTooltip>
            <br />
            <i className="fa fa-check-circle" /> Gas lines &nbsp;
            <CTooltip
              content="Repairs inside foundation walls up to the gas shut off valve on the fireplace."
            >
              <i className="fa fa-info-circle" />
            </CTooltip>
          </CCol>
        </CAccordionBody>
      </CAccordionItem>
      <CAccordionItem itemKey={3}>
        <CAccordionHeader>
          <i className="fa fa-plug me-3" /> Electrical
        </CAccordionHeader>
        <CAccordionBody>
          <CRow>
            <CCol className="text-start">
              <i className="fa fa-check-circle" /> Electrical panels, subpanels, and wiring
              <br />
              <i className="fa fa-check-circle" /> Standard circuit breakers, Arc-fault breaker troubleshooting (up to 2 hours), AFCI and GFCI breaker replacement
              <br />
              <i className="fa fa-check-circle" /> Electrical outlets, existing GCFI outlets, switches, junction boxes
              <br />
              <i className="fa fa-check-circle" /> Ceiling fans, whole house fans
              <br />
              <i className="fa fa-check-circle" /> Hardwired doorbells
              <br />
              <i className="fa fa-check-circle" /> Central vacuums &nbsp;
              <CTooltip
                content="Motor only."
              >
                <i className="fa fa-info-circle" />
              </CTooltip>
            </CCol>
          </CRow>
        </CAccordionBody>
      </CAccordionItem>
      {(plan === PlanType.basicPlus || plan === PlanType.autoPilot) &&
        <CAccordionItem itemKey={4}>
          <CAccordionHeader>
            <i className="fa fa-blender me-3" /> Appliances
          </CAccordionHeader>
          <CAccordionBody>
            <CRow>
              <CCol className="text-start">
                <i className="fa fa-check-circle" /> Dishwasher
                <br />
                <i className="fa fa-check-circle" /> Range or oven/cooktop and warming drawer &nbsp;
                <CTooltip
                  content="Includes double oven."
                >
                  <i className="fa fa-info-circle" />
                </CTooltip>
                <br />
                <i className="fa fa-check-circle" /> Range hood or downdraft
                <br />
                <i className="fa fa-check-circle" /> Microwave oven &nbsp;
                <CTooltip
                  content="Built-in only."
                >
                  <i className="fa fa-info-circle" />
                </CTooltip>
                <br />
                <i className="fa fa-check-circle" /> Trash compactor
                <br />
                <i className="fa fa-check-circle" /> Timers and clocks on built-in microwaves, ovens, and ranges
              </CCol>
            </CRow>
          </CAccordionBody>
        </CAccordionItem>
      }
      {plan === PlanType.autoPilot &&
        <CAccordionItem itemKey={4}>
          <CAccordionHeader>
            <i className="fa fa-hammer me-3" /> Handyman Services
          </CAccordionHeader>
          <CAccordionBody>
            <CRow>
              <CCol className="text-start">
                <i className="fa fa-check-circle" /> Quarterly Handyman Service &nbsp;
                <CTooltip
                  content="Up to 2 hours of handyman services per quarter (1 visit)."
                >
                  <i className="fa fa-info-circle" />
                </CTooltip>
              </CCol>
            </CRow>
          </CAccordionBody>
        </CAccordionItem>
      }
    </CAccordion>
  );
}

export default Features;

