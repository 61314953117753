import { CCard, CCardBody, CCardText, CCardTitle, CImage } from "@coreui/react";
import React from "react";
import "./contents.css";

interface ContentsProps {
  img: string,
  title: string,
  description: string,
  amount?: string,
  big?: boolean,
  id: number
}

function Contents({ img, title, description, amount, big, id }: ContentsProps) {
  return (
    <CCard className="shadow contents contents-rounded">
      <div key={id}>
        <CImage fluid src={img} alt="contents" className="content-img-rounded p-1" />
        <CCardBody>
          <CCardTitle>{title}</CCardTitle>
          <CCardText>
            {description}
            {amount}
          </CCardText>
        </CCardBody>
      </div>
    </CCard>
  );
}

export default Contents;

