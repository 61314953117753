import React from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from "react-router-dom";
import './App.css';
import LandingPage from './components/landing-page/LandingPage';
import ItemDetails from './components/landing-page/item-details/Itemdetails';
import "@fortawesome/fontawesome-free/css/all.min.css"
import { stateCities } from "./assets/datasets/kc-areas"
import LocationsList from './components/locations/LocationsList';
import BetaWelcomePage from './components/beta-welcome-page/BetaWelcomePage';
import VendorLandingPage from './components/landing-page/VendorLandingPage';
import CheckoutPage from './components/checkout/CheckoutPage';
import Privacy from './components/privacy/Privacy';
import RealtorLandingPage from 'components/landing-page/RealtorLandingPage';
import Plans from 'components/plans/Plans';
import BuyNow from 'components/buy-now/BuyNow';


function App() {
  const { id } = useParams()

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/servicer" element={<VendorLandingPage />} />
          <Route path="/realtor" element={<RealtorLandingPage />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/buy" element={<BuyNow />} />
          <Route path="/result/:id" element={<ItemDetails id={id ? +id! : 0} />} />
          <Route path="/locations" element={<LocationsList />} />
          {stateCities.map((state) =>
            state.areas.map((area) =>
              <Route path={`/${state.state_short}/${area.replace(" ", "-")}`} element={<LandingPage state={state.state_short} area={area} />} />
            )
          )}
          <Route path="/beta" element={<BetaWelcomePage />} />
          <Route path="/checkout/:id" element={<CheckoutPage />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
