export const stateCities = [
  {
    "state_short": "MO",
    "state_long": "Missouri",
    "areas": [
      "Kansas City",
      "Independence",
      "Lee's Summit",
      "Blue Springs",
      "Liberty",
      "Raytown",
      "Gladstone",
      "Grandview",
      "Belton",
      "Raymore",
      "Grain Valley",
      "Excelsior Springs",
      "Smithville",
      "Harrisonville",
      "Cameron",
      "Kearney",
      "Pleasant Hill",
      "Oak Grove",
      "Parkville",
      "Richmond",
      "Greenwood",
      "Odessa",
      "Peculiar",
      "Platte City",
      "Higginsville",
      "Lexington",
      "North Kansas City",
      "Sugar Creek",
      "Riverside",
      "Buckner",
      "Pleasant Valley",
      "Lawson",
      "Plattsburg",
      "Lake Lotawana",
      "Weatherby Lake",
      "Hamilton",
      "Garden City",
      "Gower",
      "Claycomo",
      "Lone Jack",
      "Drexel",
      "Orrick",
      "Braymer",
      "Lake Tapawingo",
      "Glenaire",
      "Hardin",
      "Polo",
      "Dearborn",
      "Avondale",
      "Osborn",
      "Sibley",
      "Kingston",
      "Henrietta",
      "Breckenridge",
      "Camden",
      "Cowgill",
      "Oaks",
      "Fleming",
      "Unity Village",
      "Levasy",
      "Randolph",
      "River Bend",
    ]
  },
  {
    "state_short": "KS",
    "state_long": "Kansas",
    "areas": [
      "Overland Park",
      "Kansas City",
      "Olathe",
      "Shawnee",
      "Lenexa",
      "Leavenworth",
      "Leawood",
      "Gardner",
      "Prairie Village",
      "Ottawa",
      "Lansing",
      "Bonner Springs",
      "Roeland Park",
      "Merriam",
      "Mission",
      "De Soto",
      "Spring Hill",
      "Paola",
      "Basehor",
      "Tonganoxie",
      "Osawatomie",
      "Louisburg",
      "Fairway",
      "Mission Hills",
      "Edwardsville",
      "Wellsville",
      "Edgerton",
      "Westwood",
    ]
  }
]




