import React from "react";
import { CRow, CCol, CImage, CButton, CContainer } from "@coreui/react";
import hohmScreen from "../../assets/screenshots/HohmScreen.png"
import Footer from "components/common/footer/Footer";
import HowItWorksCustomer from "components/landing-page/how-it-works/HowItWorksCustomer";

interface BetaWelcomePageProps {
  state?: string,
  area?: string,
}

function BetaWelcomePage({ state, area }: BetaWelcomePageProps) {
  return (
    <>
      <CContainer fluid className="hero-image mx-0 px-0">
        {/* <Navbar /> */}
        <CRow className="h-75 ps-lg-5 ps-md-0 ps-sm-0 ps-0">
          <CCol className="px-5 pb-5 mb-5 text-white d-flex align-items-end" lg={5} md={6} sm={12}>
            <CContainer>
              <h1 className="mb-0 fw-bold">Welcome Hohm, Beta Testers!</h1>
              <p>
                For {area ?? "Kansas City"} homeowners looking for the maintenance-free lifestyle of renting.
              </p>
              <CButton color="light" href="https://testflight.apple.com/join/ovUdsBXr">
                <i className="fa fa-mobile-screen me-2" />
                <b>Get the App</b>
              </CButton>
            </CContainer>
          </CCol>
        </CRow>
      </CContainer >
      <CRow className="p-5 bg-dark">
        <CCol className="text-white">
          <h2 className="text-center mb-5">Welcome To The Beta</h2>
          <p className="text-center mb-5"><b>Hohm is changing what it means to be a homeowner in {area}.</b></p>
          <h3>What is a Beta Tester?</h3>
          <p>Beta testers are "real" users of a software product that help test software in a production environment. A beta tester acts as a "trusted partner" and shares honest feedback about the look and usability of the software.</p>
          <h3>Why Was I Selected?</h3>
          <p>Because we trust you and want to hear your honest opinions of our new app. We have been working on creating this product for nearly a year and we want to make sure it's in tip-top shape before we go live!</p>
          <h3>What's My Job As A Tester?</h3>
          <p>Basically, just use the app. Here's how you can get the app:</p>
          <ol className="ms-5">
            <li>Click the "Get The App" button above and you will be directed to download an app called "Test Flight".</li>
            <li>After downloading Test Flight, open Test Flight. If it doesn't automatically ask if you want to download Hohm, come back here and click "Get The App" again.</li>
            <li>Install the Hohm app on your phone.</li>
            <li>Open the Hohm app and create an account.</li>
            <li>Once you're logged in, click around, play with the app, request services. We're trying to learn if it's easy to use, so let us know if anything isn't intuitive.</li>
          </ol>
          <h3>Aren't You Going to Tell Me How To Use the App?</h3>
          <p>Nope! We want to see how intuitive it is to learn and use. We will give you a "character profile" as to who our target customers are: homeowners who are either unwilling or incapable (due to time, health, or age) to maintain their home. These homeowners like the "maintenance-free" features of a maintenance-free community, 55+ community, or an apartment, but want to stay in their current home/neighborhood.</p>
          <h3>What now?</h3>
          <p>Download the app and get started! Please let us know if you have any questions or trouble downloading the app!</p>
          <br />
          <br />
          <p><b>P.S. One more thing!</b></p>
          <p>There are a few parts of the app that will ask for a credit card. Don't use your own! We have set up a test card number for all of our testers to use:</p>
          <ul className="ms-5">
            <li>Card Number: 4242 4242 4242 4242</li>
            <li>Expiration: 01/01</li>
            <li>CVC: 123</li>
            <li>Zip Code: 12345</li>
          </ul>
          <CButton color="light" href="https://testflight.apple.com/join/ovUdsBXr" className="mt-4">
            <i className="fa fa-mobile-screen me-2" />
            <b>Get the App</b>
          </CButton>
        </CCol>
      </CRow>
      <CRow className="p-5">
        <CCol>
          <h2 className="text-center">Get to Know Hohm</h2>
          <p className="text-center mb-5"><b>Watch our quick video to learn why we created Hohm.</b></p>

          <iframe
            className="d-flex mx-auto"
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/H53DzSnj1dk"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />

        </CCol>
      </CRow>
      <CRow className="p-5 bg-dark">
        <CCol className="text-white">
          <h2 className="text-center">We Love Your Feedback!</h2>
          <p className="text-center mb-5"><b>This is the moment we've been waiting for! Let us know you honest (even if it's too honest) opinion of the app. We'll use this to make the app even better!</b></p>

          <iframe
            className="d-flex mx-auto"
            title="Google Feedback Form"
            src="https://docs.google.com/forms/d/e/1FAIpQLSckONk6dIL_XrCzue4uBKuXLEgNbQ7dDpFfm5r-M6foUNzm8Q/viewform?embedded=true"
            width="100%"
            height="1100"
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
          >
            Loading…
          </iframe>

        </CCol>
      </CRow>
      <HowItWorksCustomer />
      <CRow className="p-5 bg-dark">
        <CCol className="text-center text-white">
          <h2 className="mb-0">Home Maintenance, Simplified</h2>
          <p>
            House tasks becoming too consuming? Considering a move to a maintenance-free community? Put your house on auto-pilot with a Hohm subscription.
          </p>
        </CCol>
      </CRow>
      <CRow className="pb-5 bg-dark">
        <CCol lg={3} md={4} sm={5} xs={6} className="mx-auto">
          <CImage fluid src={hohmScreen} />
        </CCol>
      </CRow>
      <CRow className="py-5">
        <CCol className="text-center">
          <h2>Example Subscription Services</h2>
          <p className="mb-5">
            <i>Actual subscriptions customizable to your home.</i>
          </p>
          <CRow className="d-flex justify-content-center">
            <CCol xl={2}>
              <h1>
                <i className="fa fa-leaf rounded-circle border p-3" />
              </h1>
              <p>Weekly Mowing</p>
            </CCol>
            <CCol xl={2}>
              <h1>
                <i className="fa fa-water rounded-circle border p-3" />
              </h1>
              <p>Biweekly Pool Cleaning</p>
            </CCol>
          </CRow>
          <CRow className="d-flex justify-content-center">
            <CCol xl={2}>
              <h1>
                <i className="fa fa-spray-can-sparkles rounded-circle border p-3" />
              </h1>
              <p>Weekly House Cleaning</p>
            </CCol>
            <CCol xl={2}>
              <h1>
                <i className="fa fa-shirt rounded-circle border p-3" />
              </h1>
              <p>Weekly Laundry Delivery</p>
            </CCol>
            <CCol xl={2}>
              <h1>
                <i className="fa fa-arrow-down-up-across-line rounded-circle border p-3" />
              </h1>
              <p>Quarterly Air Filter Replacement</p>
            </CCol>
          </CRow>
          <CRow className="d-flex justify-content-center">
            <CCol xl={2}>
              <h1>
                <i className="fa fa-search rounded-circle border p-3" />
              </h1>
              <p>Annual Whole Home Inspection</p>
            </CCol>
            <CCol xl={2}>
              <h1>
                <i className="fa fa-screwdriver-wrench rounded-circle border p-3" />
              </h1>
              <p>Monthly Handyman Tasks Included</p>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <Footer />
    </>
  );
}

export default BetaWelcomePage;

