import React from "react";
import { Link } from 'react-router-dom';
import "./banner.css";
import { CButton, CCol, CContainer, CRow } from "@coreui/react";

interface BannerProps {
  state?: string,
  area?: string,
}

function Banner({ state, area }: BannerProps) {
  return (
    <CContainer fluid className="hero-image owner-horizontal mx-0 px-0">
      <CRow className="h-75 ps-lg-5 ps-md-0 ps-sm-0 ps-0">
        <CCol className="px-5 pb-5 mb-5 text-white d-flex align-items-end" lg={5} md={6} sm={12}>
          <CContainer>
            <h1 className="mb-0 fw-bold">Welcome Hohm{area && `, ${area}`}!</h1>
            <p>
              For {area ?? "Kansas City"} homeowners looking for the maintenance-free lifestyle of renting.
            </p>
            <CButton
              color="light"
              href="https://appurl.io/Y0MN4Ke4kj"
            >
              <i className="fa fa-mobile-screen me-2" />
              <b>Get the App</b>
            </CButton>
          </CContainer>
        </CCol>
      </CRow>
    </CContainer >
  );
}

export default Banner;
