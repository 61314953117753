import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect } from "react"
import { useParams } from "react-router-dom";

function CheckoutPage() {
  const { id } = useParams()

  const toCheckout = async () => {
    var stripe = await loadStripe('pk_live_51KAkCOIzDRW2YovbycIJjxG1RLDWKJFRcdZj4p6AzMaiPDeXBB6F9F16mLbp16JjPOLwCzMBjb3bKdfdy7qeHGxX00aba8kPjz');
    stripe?.redirectToCheckout({
      sessionId: id!
    }).then(function (result) {
      result.error.message = 'Error'
    });
  }

  useEffect(() => {
    toCheckout()
  }, [])

  return (
    <>
      <script src="https://js.stripe.com/v3/"></script>
      <body>
      </body>
    </>
  );
}

export default CheckoutPage;

