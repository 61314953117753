import { CNavbar, CNavbarBrand, CCol, CRow, CButton, CImage, CCollapse, CContainer, CNavbarNav, CNavbarToggler, CNavItem, CNavLink } from "@coreui/react";
import "./Navbar.css";
import icon from "../../../assets/icon_no_shadow.png"
import TopBanner from "components/common/top-banner/TopBanner";
import { useState } from "react";
import { UserType } from "enums/UserType";

interface NavBarProps {
  userType?: UserType,
}

function NavBar({ userType }: NavBarProps) {
  const [visible, setVisible] = useState(false)

  return (
    <CNavbar expand="lg" className="navbar-light py-0" placement="sticky-top" color="light">
      <CRow className="w-100 mb-2">
        <CCol className="px-0">
          <TopBanner userType={userType} />
          <CContainer fluid>
            <CRow className="w-100 mx-md-5 mx-sm-2 mx-2 my-3">
              <CCol className="my-auto d-lg-none d-md-block d-sm-block d-block">
                <CNavbarToggler
                  onClick={() => setVisible(!visible)}
                  className="text-primary"
                />
              </CCol>
              <CCol className="my-auto d-lg-block d-md-none d-sm-none d-none">
                <CRow>
                  <CCol>
                    <a className="" href="/plans">
                      <p className="text-dark float-end mb-0 fw-bold">
                        OUR PLANS
                      </p>
                    </a>
                  </CCol>
                  <CCol>
                    <a className="" href="/vendors">
                      <p className="text-dark float-end mb-0 fw-bold">
                        WHAT IS HOHM?
                      </p>
                    </a>
                  </CCol>
                </CRow>
              </CCol>
              <CCol className="text-md-center">
                <CNavbarBrand href="/" className="me-0">
                  <CImage fluid width={140} src={icon} className="mb-1" />
                </CNavbarBrand>
              </CCol>
              <CCol className="my-auto text-center d-lg-block d-md-none d-sm-none d-none">
                <CButton
                  color="secondary"
                  href="tel:913-220-5546"
                >
                  Call Us <i className="fa fa-phone" />
                </CButton>
                <CButton
                  color="primary"
                  shape="rounded-pill"
                  href="/buy"
                  className="ms-3"
                >
                  <b>Get Started</b>
                </CButton>
              </CCol>
            </CRow>
            <CCollapse className="navbar-collapse" visible={visible}>
              <CNavbarNav className="ms-3 d-lg-none d-md-block d-sm-block d-block">
                <CNavItem>
                  <CNavLink href="/plans" active>
                    <p className="text-dark mb-0 fw-bold">
                      OUR PLANS
                    </p>
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink href="#">
                    <p className="text-dark mb-0 fw-bold">
                      WHAT IS HOHM?
                    </p>
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink href="tel:913-229-4846">
                    <p className="text-dark mb-0 fw-bold">
                      CALL US
                    </p>
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink
                    href={userType === UserType.servicer ?
                      "https://apps.apple.com/gb/app/hohm-vendor/id1616536276" :
                      "https://apps.apple.com/gb/app/hohm-owner/id1616527938"
                    }>
                    <p className="text-dark mb-0 fw-bold">
                      GET THE APP
                    </p>
                  </CNavLink>
                </CNavItem>
                <CNavItem className="my-2">
                  <CButton
                    color="primary"
                    shape="rounded-pill"
                    href="/buy"
                  >
                    <b>Get Started</b>
                  </CButton>
                </CNavItem>
              </CNavbarNav>
            </CCollapse>
          </CContainer>
        </CCol>
      </CRow>

    </CNavbar >
  );
}

export default NavBar;